
























































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@nuxtjs/composition-api';
import VueCountryCode from 'vue-country-code';
import Icon from '../../almarwan/components/Icon.vue';
import {
  useCountrySearch,
  useNewsletter,
  useUiNotification,
} from '../../composables';
import type { Country } from '../../modules/GraphQL/types';
import addressGetter from '../../modules/customer/getters/addressGetter';
import Logo from '../../components/Header/Logo.vue';

export default defineComponent({
  name: 'KobelcoLimitedOffer',
  components: { Icon, VueCountryCode, Logo },
  setup() {
    const body = ref(null);
    const showModal = ref(false);
    const dialCode = ref('');
    const countries = ref<Country[]>([]);
    const countriesList = computed(() =>
      addressGetter.countriesList(countries.value)
    );
    const { load: loadCountries } = useCountrySearch();

    const isFormValid = computed(() => {
      return (
        firstName.value != '' &&
        email.value != '' &&
        phoneNumber.value != '' &&
        email.value != '' &&
        isValidEmail(email.value)
      );
    });

    onMounted(() => {
      body.value = document.body;
    });
    const openModal = async() => {
      showModal.value = true;
      body.value.classList.add('stop');
      countries.value = await loadCountries();
      
    };
    const onSelectCode = (event) => {
      countryCode.value = event.value;
      dialCode.value = event.dialCode;
    };
    const closeModal = () => {
      showModal.value = false;
      firstName.value = '';
      email.value = '';
      phoneNumber.value = '';
      company.value = '';
      body.value.classList.remove('stop');
    };

    const showError = ref(false);
    const showSuccess = ref(false);
    const showErrorMessage = ref(false);
    const isValid = ref(false);
    const email = ref('');
    const firstName = ref('');
    const lastName = ref('');
    const phoneNumber = ref('');
    const company = ref('');
    const title = ref('');
    const country = ref('');
    const countryCode = ref('');
    const { loading } = useNewsletter();
    const { send: sendNotification } = useUiNotification();

    const submitForm = () => {
      if (validateForm()) {
        subscribe();
      } else {
        showError.value = true;
      }
    };
    const validateForm = () => {
      if (email && !isValidEmail(email.value)) {
        showErrorMessage.value = true;
        return false;
      }

      return true;
    };
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    const subscribe = async () => {
      showErrorMessage.value = false;
      const body = {
        webform_id: 'kobelco-excavators-zero-down-payment',
        name: firstName.value.toString(),
        email: email.value.toString(),
        company: company.value.toString(),
        phone: dialCode.value.toString() + phoneNumber.value.toString(),
      };
      try {
        const response = await fetch(
          `https://hook.eu1.make.com/uqcx9ttbjlh5l6lu62lip4qrnh64n3gd`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          }
        );
        if (response.status == 200) {
          sendNotification({
            id: Symbol('product_added_to_cart_from_wishlist'),
            message: `We've received your details, one of our representatives will contact you shortly.`,
            type: 'success',
            icon: 'check',
            persist: false,
            title: 'Register Interest',
          });
          closeModal();
        } else {
          sendNotification({
            id: Symbol('product_removed'),
            message: 'Something went wrong, try again.',
            type: 'warning',
            icon: 'error',
            persist: false,
            title: 'Invalid amount!',
          });
        }
      } catch (error) {
        sendNotification({
          id: Symbol('product_removed'),
          message: 'Something went wrong, try again.',
          type: 'warning',
          icon: 'error',
          persist: false,
          title: 'Invalid amount!',
        });
      }
    };

    return {
      showModal,
      openModal,
      closeModal,
      showError,
      showSuccess,
      showErrorMessage,
      email,
      subscribe,
      loading,
      lastName,
      company,
      country,
      phoneNumber,
      title,
      firstName,
      countriesList,
      submitForm,
      countryCode,
      onSelectCode,
      dialCode,
      isFormValid,
      isValid,
    };
  },
  head() {
    const title = this.$t('KobelcoZeroDownPaymentTitle').toString();
    const description = this.$t('KobelcoZeroDownPaymentDesc').toString();
    return {
      title,
      meta: [
        { hid: 'robots',
          name: 'robots', 
          content: 'noindex,nofollow' 
        },
        {
          hid: 'title',
          name: 'title',
          content: title
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: title,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: title,
        },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description,
        },
        {
          hid: 'url',
          name: 'url',
          content: 'https://almarwan.com' + this.$route.path,
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: 'https://almarwan.com' + this.$route.path,
        },
        {
          hid: 'site_name',
          name: 'site_name',
          content: title,
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: title,
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: title,
        },
        {
          hid: 'og:apple-mobile-web-app-title',
          name: 'og:apple-mobile-web-app-title',
          content: title,
        },
        {
          property: 'og:image',
          content: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
        },
        {
          property: 'twitter:image',
          content: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
        }
      ],
      script: [
      {
          type: 'application/ld+json',
          class: 'saswp-schema-markup-output',
          json: {
            "@context": "https://schema.org",
	            "@type": "WebPage",
	            "name": title,
	            "url": 'https://almarwan.com' + this.$route.path,
	            "description": description,
	            "publisher": { "@type": "Organization", "name": "Al Marwan Heavy Machinery", "logo": { "@type": "ImageObject", "url": "https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png", "width": 96, "height": 96 } },
	            "mainEntityOfPage": { "@type": "WebPage", "@id": 'https://almarwan.com' + this.$route.path }
          },
        }
      ]
    };
  },
});
